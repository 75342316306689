import imageSrc from '../../assets/load-static.png'

function Result() {
  return (
    <div className='flex-col align-center'>
      <img src={imageSrc} alt='' width={100} height={100} />
      <h2 style={{ margin: '1rem 0' }}>Your application is in review</h2>
      <p className='text-center'>
        This process will take 5-7 business days, we will contact you once this has been reviewed.
      </p>
    </div>
  )
}

export default Result
