import whiteLogo from '../assets/logo-white.png'

const LOGO_URL = whiteLogo

const LOANS_OLD = 'LOANS_OLD'
const LOANS_NEW = 'LOANS_NEW'
const PAY_OLD = 'PAY_OLD'
const PAY_NEW = 'PAY_NEW'

const LOANS_OLD_PHASE_MAX = 3
const LOANS_OLD_PHASE_1 = 'Personal Information'
const LOANS_OLD_PHASE_2 = 'Identity Document'
const LOANS_OLD_PHASE_3 = 'Verification Result'

const LOANS_NEW_PHASE_MAX = 4
const LOANS_NEW_PHASE_1 = 'Personal Information'
const LOANS_NEW_PHASE_2 = 'Identity Verification'
const LOANS_NEW_PHASE_3 = 'Fico Score'
const LOANS_NEW_PHASE_4 = 'Verification Result'

const PAY_OLD_PHASE_MAX = 3
const PAY_OLD_PHASE_1 = 'Confirm Consent'
const PAY_OLD_PHASE_2 = 'Payment Document'
const PAY_OLD_PHASE_3 = 'Payment Result'

const PAY_NEW_PHASE_MAX = 3
const PAY_NEW_PHASE_1 = 'Confirm Consent'
const PAY_NEW_PHASE_2 = 'Make Payment'
const PAY_NEW_PHASE_3 = 'Payment Result'

const {
  REACT_APP_SERVICE_URL: SERVICE_URL = 'http://localhost:3030',
} = process.env

export {
  SERVICE_URL,
  LOGO_URL,
  LOANS_OLD,
  LOANS_NEW,
  PAY_OLD,
  PAY_NEW,
  LOANS_OLD_PHASE_MAX,
  LOANS_OLD_PHASE_1,
  LOANS_OLD_PHASE_2,
  LOANS_OLD_PHASE_3,
  LOANS_NEW_PHASE_MAX,
  LOANS_NEW_PHASE_1,
  LOANS_NEW_PHASE_2,
  LOANS_NEW_PHASE_3,
  LOANS_NEW_PHASE_4,
  PAY_OLD_PHASE_MAX,
  PAY_OLD_PHASE_1,
  PAY_OLD_PHASE_2,
  PAY_OLD_PHASE_3,
  PAY_NEW_PHASE_MAX,
  PAY_NEW_PHASE_1,
  PAY_NEW_PHASE_2,
  PAY_NEW_PHASE_3,
}
