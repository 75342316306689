import { useState, useEffect } from 'react'
import { useSteps } from '../../contexts/JourneyStepContext'
import * as consts from '../../constants'
import Button from '../../components/Button'
import Stepper from '../../components/Stepper'
import PersonalInfo from '../../components/JourneyComponents/PersonalInfo'
import ManageVerification from './ManageVerification'
import FicoScore from './FicoScore'
import VerificationResult from './VerificationResult'

const initialInputState = {
  fullName: '',
  email: '',
  phone: '',
}

function Journey({ onSuccess }) {
  const { step, increment, decrement } = useSteps()
  const [inputData, setInputData] = useState(initialInputState)
  const [verified, setVerified] = useState(null)
  const [scoreDone, setScoreDone] = useState(false)

  const handleChange = (name, val) => {
    setInputData((prev) => ({
      ...prev,
      [name]: val,
    }))
  }

  const clickConfirm = () => {
    setInputData(initialInputState)
    setVerified(null)
    onSuccess()
  }

  useEffect(() => {
    if (verified !== null) increment()
  }, [verified]) // eslint-disable-line

  return (
    <div className='flex-col' style={{ maxWidth: '500px' }}>
      <Stepper activeStep={step - 1} product={consts.LOANS_NEW} />

      <div style={{ margin: '2rem auto' }}>
        {(() => {
          switch (step) {
            case 1:
              return <PersonalInfo data={inputData} handleChange={handleChange} />

            case 2:
              return <ManageVerification data={inputData} setVerified={setVerified} />

            case 3:
              return <FicoScore setScoreDone={setScoreDone} />

            case 4:
              return <VerificationResult verified={verified} clickConfirm={clickConfirm} />

            default:
              return null
          }
        })()}
      </div>

      <div className='flex-row-center'>
        <Button onClick={decrement} disabled={step === 1} invert>
          Back
        </Button>
        <Button
          onClick={step === consts.LOANS_NEW_PHASE_MAX ? clickConfirm : increment}
          disabled={
            (step === 1 &&
              (!inputData || !inputData['fullName'] || !inputData['email'] || !inputData['phone'])) ||
            (step === 2 && verified === null) ||
            (step === 3 && !scoreDone)
          }>
          {step === consts.LOANS_NEW_PHASE_MAX ? 'Confirm' : 'Proceed'}
        </Button>
      </div>
    </div>
  )
}

export default Journey
