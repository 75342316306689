import JourneyStepProvider, { JourneyStepContext } from '../../contexts/JourneyStepContext'
import * as consts from '../../constants'
import Journey from '../../journeys/PayEverybyte'

function PayEverybyte() {
  return (
    <JourneyStepProvider maxSteps={consts.PAY_NEW_PHASE_MAX}>
      <JourneyStepContext.Consumer>
        {({ step, reset }) => (
          <div className='page grey-shadow'>
            <div className='page-head'>
              <h2>
                <span>Payments &gt; Make Payment &gt;</span> {consts[`PAY_NEW_PHASE_${step}`]}
              </h2>
            </div>

            <Journey
              onSuccess={() => {
                reset()
              }}
            />
          </div>
        )}
      </JourneyStepContext.Consumer>
    </JourneyStepProvider>
  )
}

export default PayEverybyte
