function PaymentResult({ success }) {
  if (success) {
    return (
      <div className='text-center'>
        <h2>Yay!</h2>
        <br />
        <p>
          Your payment has been accepted, and is being processed.
          You can safely leave this screen!
        </p>
      </div>
    )
  }

  return (
    <div className='text-center'>
      <h2>Oh no!</h2>
      <br />
      <p>
        Your payment has not been accepted.
        Please contact us or try again!
      </p>
    </div>
  )
}

export default PaymentResult
