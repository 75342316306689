import { useEffect, useRef } from 'react'

function Button({
  children,
  type = 'button',
  disabled = false,
  onClick = () => console.log('click'),
  invert = false,
  transparent = false,
  style = {},
}) {
  const ref = useRef(null)
  const linearGradient = 'linear-gradient(to right, blue -50%, var(--blue) 50%)'

  const hoverEffect = () => {
    if (!disabled && !transparent) {
      ref.current.style.background = invert ? 'var(--grey)' : 'darkblue'
    }
  }

  const unhoverEffect = () => {
    if (!transparent) {
      ref.current.style.background = invert ? 'whitesmoke' : linearGradient
    }
  }

  useEffect(() => {
    if (disabled) unhoverEffect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        minWidth: '100px',
        margin: transparent ? '0' : '0 0.5rem',
        padding: transparent ? '0' : '0.5rem 1rem',
        border: 'none',
        borderRadius: '1rem',
        background: transparent ? 'transparent' : invert ? 'whitesmoke' : linearGradient,
        color: transparent || invert ? 'var(--blue)' : 'whitesmoke',
        fontSize: '0.9rem',
        boxShadow: transparent ? 'none' : '0px 1px 3px 1px var(--grey)',
        cursor: disabled ? 'unset' : 'pointer',
        opacity: disabled ? '0.4' : '1',
        ...style,
      }}
      onMouseEnter={hoverEffect}
      onMouseLeave={unhoverEffect}>
      {children}
    </button>
  )
}

export default Button
