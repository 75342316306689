"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SWEET_ALERT_DEFAULT_PROPS = void 0;
exports.SWEET_ALERT_DEFAULT_PROPS = {
    allowEscape: true,
    closeOnClickOutside: true,
    inputType: "text",
    customClass: "",
    validationMsg: null,
    validationRegex: null,
    hideOverlay: false,
    required: true,
    disabled: false,
    focusConfirmBtn: true,
    focusCancelBtn: false,
    showCloseButton: false,
    confirmBtnBsStyle: "primary",
    cancelBtnBsStyle: "link",
    beforeMount: function () { },
    afterMount: function () { },
    beforeUpdate: null,
    afterUpdate: function () { },
    beforeUnmount: function () { },
    style: {},
    closeBtnStyle: {},
    timeout: 0,
    openAnim: { name: "showSweetAlert", duration: 300 },
    closeAnim: false,
    reverseButtons: false,
    dependencies: [],
};
