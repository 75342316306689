import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import { Routes, Route, Navigate } from 'react-router-dom'
import LoanOldschool from './pages/LoanOldschool'
import LoanEverybyte from './pages/LoanEverybyte'
import PayOldschool from './pages/PayOldschool'
import PayEverybyte from './pages/PayEverybyte'

function App() {
  return (
    <div>
      <Navbar />
      <div className='flex-row'>
        <Sidebar />
        <main>
          <Routes>
            <Route exact path='/request-oldschool' element={<LoanOldschool />} />
            <Route exact path='/request' element={<LoanEverybyte />} />
            <Route exact path='/pay-oldschool' element={<PayOldschool />} />
            <Route exact path='/pay' element={<PayEverybyte />} />

            <Route path='*' element={<Navigate to='/request' />} />
          </Routes>
        </main>
      </div>
    </div>
  )
}

export default App
