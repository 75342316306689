import { Navbar as ImportedNavbar } from '@everybyte-io/components'
import { LOGO_URL } from '../constants'

function Navbar() {
  return (
    <ImportedNavbar
      logoUri={LOGO_URL}
      pathName={window.location.pathname}
      projects={[
        {
          label: 'Everybyte',
          onClick: () => console.log('click'),
          selected: true,
        },
      ]}
      items={[]}
      navIcons={[]}
    />
  )
}

export default Navbar
