import { useSteps } from '../../contexts/JourneyStepContext'
import * as consts from '../../constants'
import Button from '../../components/Button'
import Stepper from '../../components/Stepper'
import ManagePayment from './ManagePayment'
import ConsentTable from '../../components/ConsentTable'
import { useState } from 'react'
import PaymentResult from './PaymentResult'

const LOAN_AMOUNT = 25000
const LOAN_INSTALLMENTS = 24
const CURR_INSTALLMENT = 1

const data = {
  amount: (LOAN_AMOUNT / LOAN_INSTALLMENTS).toFixed(2),
  currency: 'usd',
  description: 'Loan Request',
}

const formatCurrency = (num) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data.currency,
  }).format(num)

function Journey({ onSuccess }) {
  const { step, increment, decrement, reset } = useSteps()
  const [success, setSuccess] = useState(false)

  return (
    <div className='flex-col' style={{ maxWidth: '420px' }}>
      <Stepper activeStep={step - 1} product={consts.PAY_NEW} />

      <div style={{ width: '100%', margin: '2rem auto' }}>
        {(() => {
          switch (step) {
            case 1:
              return (
                <div>
                  <h2 className='text-center'>Pay back your loan</h2>
                  <br />
                  <ConsentTable
                    rows={[
                      { key: 'Description', value: data.description },
                      { key: 'Loan Amount', value: formatCurrency(LOAN_AMOUNT) },
                      { key: '', value: '' },
                      { key: 'Installment', value: `${CURR_INSTALLMENT}/${LOAN_INSTALLMENTS}` },
                      { key: 'Pay Amount', value: formatCurrency(data.amount) },
                    ]}
                  />
                </div>
              )

            case 2:
              return (
                <ManagePayment
                  data={data}
                  onSuccess={() => {
                    setSuccess(true)
                    increment()
                  }}
                  onFail={() => {
                    setSuccess(false)
                    increment()
                  }}
                  onAbort={() => {
                    reset()
                  }}
                />
              )

            case 3:
              return <PaymentResult success={success} />

            default:
              return null
          }
        })()}
      </div>

      <div className='flex-row-center'>
        <Button onClick={decrement} invert disabled={step === 1 || step === consts.PAY_NEW_PHASE_MAX}>
          Back
        </Button>
        <Button onClick={step === consts.PAY_NEW_PHASE_MAX ? onSuccess : increment} disabled={step === 2}>
          {step === consts.PAY_NEW_PHASE_MAX ? 'Done' : 'Confirm'}
        </Button>
      </div>
    </div>
  )
}

export default Journey
