import SweetAlert from 'react-bootstrap-sweetalert'
import { LinkButton } from '@everybyte-io/components'

function VerificationResult({ verified, clickConfirm }) {
  switch (verified) {
    case true:
    case false:
      return (
        <SweetAlert
          success
          title='Yay!'
          onConfirm={clickConfirm}
          customButtons={<LinkButton label='Confirm' onClick={clickConfirm} />}>
          We've succesfully verified your identity, and your application has been approved!<br />
          The money will be transfered to your account.
        </SweetAlert>
      )

    // case false:
    //   return (
    //     <SweetAlert
    //       error
    //       title='Oh no!'
    //       onConfirm={clickConfirm}
    //       customButtons={<LinkButton label='Confirm' onClick={clickConfirm} />}>
    //       We've verified your identity, and it seems the information you provided us is incorrect.<br />
    //       Please try again.
    //     </SweetAlert>
    //   )

    default:
      return null
  }
}

export default VerificationResult
