function ConsentTable({ rows = [] }) {
  return (
    <table className='consent-table'>
      <tbody>
        {rows.map((item, idx) => (
          <tr key={`tr:${idx}`}>
            <td>{item.key}</td>
            <td>{item.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ConsentTable
