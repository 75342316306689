import { createContext, useContext, useState } from 'react'

const JourneyStepContext = createContext()

const useSteps = () => useContext(JourneyStepContext)

function JourneyStepProvider({ children, maxSteps }) {
  const [step, setStep] = useState(1)

  const increment = () => {
    setStep((prev) => {
      if (prev === maxSteps) {
        return prev
      }

      return prev + 1
    })
  }

  const decrement = () => {
    setStep((prev) => {
      if (prev === 1) {
        return prev
      }

      return prev - 1
    })
  }

  const reset = () => {
    setStep(1)
  }

  return (
    <JourneyStepContext.Provider value={{ step, setStep, increment, decrement, reset }}>
      {children}
    </JourneyStepContext.Provider>
  )
}

export default JourneyStepProvider
export { JourneyStepContext, useSteps }
