import { useNavigate } from 'react-router-dom'
import { Sidebar as ImportedSidebar } from '@everybyte-io/components'

function Sidebar() {
  const navigate = useNavigate()
  const pathName = window.location.pathname

  return (
    <ImportedSidebar
      title='Best Loans'
      items={[
        {
          label: 'Request - Oldschool',
          onClick: () => navigate('/request-oldschool'),
          selected: pathName === '/request-oldschool',
        },
        {
          label: 'Request - Everybyte',
          onClick: () => navigate('/request'),
          selected: pathName === '/request',
        },
        {
          label: 'Pay - Oldschool',
          onClick: () => navigate('/pay-oldschool'),
          selected: pathName === '/pay-oldschool',
        },
        {
          label: 'Pay - Everybyte',
          onClick: () => navigate('/pay'),
          selected: pathName === '/pay',
        }
      ]}
    />
  )
}

export default Sidebar
