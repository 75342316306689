import { useEffect, useState } from 'react'
import axios from 'axios'
import { SERVICE_URL } from '../../constants'
import { Loader } from '@everybyte-io/components'

// eslint-disable-next-line no-undef
const sdk = new JourneySDK()
sdk.frontUrl = 'https://identity-journey.everybyte.io'

function ManageVerification({ data, setVerified }) {
  const [accessToken, setAccessToken] = useState('')
  const [sessionId, setSessionId] = useState('')

  const getToken = async () => {
    try {
      const {
        data: { accessToken: token },
      } = await axios.get(`${SERVICE_URL}/access-token`)

      setAccessToken(token)
    } catch (error) {
      console.error(error)
    }
  }

  const createSession = async () => {
    try {
      const {
        data: { sessionId: id },
      } = await axios.post(
        `${SERVICE_URL}/identity-session`,
        {
          ...data,
          accessToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )

      setSessionId(id)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!accessToken) getToken()
    if (accessToken && !sessionId) createSession()
    if (sessionId) {
      sdk.init({
        sandbox: true,
        sessionId,
        onSuccess: ({ fullName, email, phone }) => {
          if (fullName === 'verified' && email === 'verified' && phone === 'verified') {
            setVerified(true)
          } else {
            setVerified(false)
          }
        },
      })
    }
  }, [accessToken, sessionId]) // eslint-disable-line

  if (!accessToken) {
    return <Loader label='Authorizing this request...' />
  }

  if (!sessionId) {
    return <Loader label='Creating a session...' />
  }

  return <Loader label='Running identity verification...' />
}

export default ManageVerification
