import { Step, StepLabel, Stepper as MuiStepper } from '@mui/material'
import * as consts from '../constants'

function Stepper({ activeStep = 0, product = '' }) {
  return (
    <MuiStepper activeStep={activeStep} alternativeLabel>
      {new Array(consts[`${product}_PHASE_MAX`]).fill(0).map((x, idx) => (
        <Step key={idx}>
          <StepLabel>{consts[`${product}_PHASE_${idx + 1}`].split(' ')[1]}</StepLabel>
        </Step>
      ))}
    </MuiStepper>
  )
}

export default Stepper
