import { Input } from '@everybyte-io/components'
import PhoneInput from 'react-phone-input-2'
import { Person, AlternateEmail } from '@mui/icons-material'

function PersonalInfo({ data, handleChange }) {
  return (
    <div className='flex-col' style={{ width: '420px' }}>
      <Input
        label='Full name'
        placeholder='John Doe'
        type='text'
        name='fullName'
        value={data['fullName']}
        setValue={(val) => handleChange('fullName', val)}
        required
        style={{ margin: '0.5rem 0' }}
        icon={Person}
      />
      <Input
        label='Email address'
        placeholder='john.doe@example.com'
        type='email'
        name='email'
        value={data['email']}
        setValue={(val) => handleChange('email', val)}
        required
        style={{ margin: '0.5rem 0' }}
        icon={AlternateEmail}
      />
      <PhoneInput
        specialLabel='Phone number'
        country='il'
        value={data['phone']}
        onChange={(val) => handleChange('phone', val)}
        style={{ margin: '0.5rem 0' }}
      />
    </div>
  )
}

export default PersonalInfo
