import { readFile } from 'react-avatar-selector'
import uploadImage from '../../assets/upload.png'

function PaymentDocument({ pic, setPic }) {
  const handleChange = (e) => {
    readFile(e, (file) => {
      setPic(file)
    })
  }

  const uploadStyles = { width: '150px' }

  return (
    <div className='flex-col align-center'>
      <h3>Upload a payment document</h3>
      <h4>Ex. cheque</h4>
      <br />

      <div style={{ ...uploadStyles, position: 'relative' }}>
        <img src={uploadImage} alt='' style={uploadStyles} />
        <input
          type='file'
          onChange={handleChange}
          style={{
            width: '100%',
            height: '100%',
            opacity: '0',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '999',
            cursor: 'pointer',
          }}
        />
      </div>

      {pic && <img src={pic.base64} alt='' style={{ maxWidth: '350px', maxHeight: '200px' }} />}
    </div>
  )
}

export default PaymentDocument
