import AvatarSelector from 'react-avatar-selector'

function IdentityDocument({ pic, setPic }) {
  return (
    <div className='flex-col align-center'>
      <h3>Upload an identification document</h3>
      <h4>Ex. passport / national ID</h4>
      <br />
      <AvatarSelector value={pic} onChange={(value) => setPic(value)} />
    </div>
  )
}

export default IdentityDocument
