"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const readFile = (e, callback) => {
    const allFiles = e.target.files;
    if (allFiles === null || allFiles === void 0 ? void 0 : allFiles.length) {
        const file = allFiles[0];
        const reader = new FileReader();
        // Convert the file to base64 text
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64 = reader.result;
            const fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64,
            };
            callback(fileInfo);
        };
    }
    else {
        console.warn('No file selected');
    }
};
exports.default = readFile;
