import { Input } from '@everybyte-io/components'
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material'

function LoanInfo({ data, handleChange }) {
  return (
    <div className='flex-col'>
      <div className='flex-row align-center'>
        <FormControl fullWidth>
          <InputLabel id='permissible-purpose'>Permissible purpose</InputLabel>
          <Select
            labelId='permissible-purpose'
            label='Permissible purpose'
            value={data['permissiblePurpose']}
            onChange={(e) => handleChange('permissiblePurpose', e.target.value)}>
            {[
              'Credit application',
              'Employment purposes',
              'Employee eligibility',
              'Child support payments',
              'Review of employee',
              'Employee request',
              'Subpoena',
              'Insurance underwriting',
              'Risk assessment',
              'Legitimate need to review',
              'Ligitimate need inititated',
            ].map((txt, idx) => (
              <MenuItem key={`${idx}:${txt}`} value={txt}>
                {txt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ width: '1rem' }} />

        <Input
          label='Loan number'
          placeholder='370119865'
          type='text'
          value={data['loanNumber']}
          setValue={(val) => handleChange('loanNumber', val)}
          required
          style={{ margin: '0' }}
        />
      </div>

      <TextField
        label='Additional Information'
        multiline
        type='text'
        value={data['additionalInformation']}
        onChange={(e) => handleChange('additionalInformation', e.target.value)}
        variant='outlined'
        style={{ marginTop: '1rem' }}
      />
    </div>
  )
}

export default LoanInfo
