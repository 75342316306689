import { useEffect, useState } from 'react'
import { Loader } from '@everybyte-io/components'
import scoreImage from '../../assets/fico-score.png'

const SCORE = 640

function FicoScore({ setScoreDone }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setScoreDone(true)
    }, 3000)
  }, [])

  if (loading) {
    return <Loader label='Calculating FICO score...' />
  }

  return (
    <div className='text-center'>
      <h3>Your FICO &reg; score is</h3>
      <h1>{SCORE}!</h1>

      <img src={scoreImage} alt='' />
    </div>
  )
}

export default FicoScore
