import { useState } from 'react'
import { useSteps } from '../../contexts/JourneyStepContext'
import * as consts from '../../constants'
import Button from '../../components/Button'
import Stepper from '../../components/Stepper'
import PersonalInfo from '../../components/JourneyComponents/PersonalInfo'
import LoanInfo from './LoanInfo'
import IdentityDocument from './IdentityDocument'
import Result from '../../components/JourneyComponents/OldschoolResult'

const initialInputState = {
  fullName: '',
  email: '',
  phone: '',
  permissiblePurpose: '',
  loanNumber: '',
  additionalInformation: '',
}

function Journey({ onSuccess }) {
  const { step, increment, decrement } = useSteps()
  const [inputData, setInputData] = useState(initialInputState)
  const [pic, setPic] = useState(null)

  const handleChange = (name, val) => {
    setInputData((prev) => ({
      ...prev,
      [name]: val,
    }))
  }

  const clickConfirm = () => {
    setInputData(initialInputState)
    setPic(null)
    onSuccess()
  }

  return (
    <div className='flex-col' style={{ maxWidth: '500px' }}>
      <Stepper activeStep={step - 1} product={consts.LOANS_OLD} />

      <div style={{ margin: '2rem auto' }}>
        {(() => {
          switch (step) {
            case 1:
              return (
                <div>
                  <PersonalInfo data={inputData} handleChange={handleChange} />
                  <div
                    style={{
                      width: '100%',
                      height: '7px',
                      margin: '1rem 0 2rem 0',
                      backgroundColor: 'var(--grey)',
                      borderRadius: '1rem',
                    }}
                  />
                  <LoanInfo data={inputData} handleChange={handleChange} />
                </div>
              )

            case 2:
              return <IdentityDocument pic={pic} setPic={setPic} />

            case 3:
              return <Result />

            default:
              return null
          }
        })()}
      </div>

      <div className='flex-row-center'>
        <Button onClick={decrement} disabled={step === 1} invert>
          Back
        </Button>
        <Button
          onClick={step === consts.LOANS_OLD_PHASE_MAX ? clickConfirm : increment}
          disabled={
            (step === 1 &&
              (!inputData ||
                !inputData['fullName'] ||
                !inputData['email'] ||
                !inputData['phone'] ||
                !inputData['permissiblePurpose'] ||
                !inputData['loanNumber'])) ||
            (step === 2 && !pic)
          }>
          {step === consts.LOANS_OLD_PHASE_MAX ? 'Confirm' : 'Proceed'}
        </Button>
      </div>
    </div>
  )
}

export default Journey
